import { AbstractControl } from "@angular/forms";

/**
 * Regex being used to validate phone numbers.
 *
 * NOTE: Does not match country codes
 *
 * Same as in app/models/VirtualCarSaleService.scala
 *
 * Source: www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch04s02.html
 */
const phoneNumberRegex = /^(\+?[0-9]{1,3})?[-. ]?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

/**
 * Additional validation rules for phone numbers
 */
function hasValidAreaCode(phone: string): boolean {
  const areaCode = phone.replace(/\D/g, '').slice(0, 3);
  // Area codes can't start with 0 or 1, and can't be N11 (like 911)
  return !/^[01]/.test(areaCode) && !/^(.)\1\1$/.test(areaCode) && areaCode !== '911';
}

function hasValidExchangeCode(phone: string): boolean {
  const exchangeCode = phone.replace(/\D/g, '').slice(3, 6);
  // Exchange codes can't start with 0 or 1
  return !/^[01]/.test(exchangeCode);
}

function hasReasonableRepeats(phone: string): boolean {
  const digits = phone.replace(/\D/g, '');
  // Check for more than 4 repeated digits in a row
  return !/(.)\1{4,}/.test(digits);
}

/**
 * Phone number validator for Reactive Forms
 *
 * @param control Form control object
 * @returns Null if the phone number is valid, otherwise an object containing validation errors
 */
export default function phoneNumberValidator(control: AbstractControl) {
  if (control.value === "") return null;

  const value = control.value;
  
  // Check all validation rules
  const isValid = phoneNumberRegex.test(value) &&
                 hasValidAreaCode(value) &&
                 hasValidExchangeCode(value) &&
                 hasReasonableRepeats(value);

  return isValid ? null : { 'invalidPhoneNumber': true };
}
